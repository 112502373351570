<template>
  <b-row>
    <b-col cols="12">
      <chart-candlestick />
    </b-col>
    <b-col cols="4">
      <b-card>
        <div class="d-flex justify-content-between">
          <div>
            <small>SALDO DISPONÍVEL</small>
            <h3>$0.00</h3>
            <small>R$ 0,00</small>
          </div>
          <div>
            <small>EM ORDENS</small>
            <h5>$0.00</h5>
          </div>
        </div>
        <b-button variant="primary" size="sm" class="mt-1" block>Vender</b-button>
        <hr />
        <div class="d-flex justify-content-between">
          <div>
            <small>ÚLTIMO PREÇO</small>
            <h3 class="text-danger">R$ 1,46</h3>
          </div>
          <div>
            <small>VARIAÇÃO 24H</small>
            <h3 class="text-danger">-1.75%</h3>
          </div>
        </div>
        <div>
          <small>MÁXIMA 24H</small>
          <h3>R$ 1,48</h3>
        </div>
        <div>
          <small>MÍNIMA 24H</small>
          <h3>R$ 1,44</h3>
        </div>
        <div>
          <small>VOLUME 24H</small>
          <h3>$237.00</h3>
        </div>
      </b-card>
    </b-col>
    <b-col cols="8">
      <b-card title="Ofertas">
        <b-table small :fields="fields" :items="items" responsive="sm">
          <template #cell(seller)="data">
            {{ data.value.username }}
          </template>

          <template #cell(reliable)="data">
            <b-progress
              :value="data.value.value"
              max="100"
              :variant="data.value.variant"
              striped
            />
          </template>

          <template #cell(order_status)="data">
            <b-badge pill :variant="data.value.variant">
              {{ data.value.status }}
            </b-badge>
          </template>

          <template #cell(price)="data">
            {{ "$" + data.value }}
          </template>

          <template #cell(action)>
            <b-button variant="success" size="sm">Comprar</b-button>
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BTable, BProgress, BBadge } from "bootstrap-vue";
import ChartCandlestick from "./chart/ApexCandlestickChart.vue"

export default {
  components: {
    BTable,
    BProgress,
    BBadge,
    ChartCandlestick
  },
  data() {
    return {
      fields: [
        { key: "seller", label: "Vendedor" },
        { key: "price", label: "Preço" },
        { key: "qtd", label: "Qtd." },
        { key: "total", label: "Total" },
        { key: "reliable", label: "Confiável" },
        { key: "order_status", label: "Status da ordem" },
        { key: "action", label: "Ação" },
      ],
      items: [
        {
          seller: { username: "jonatascaires" },
          price: 1.4,
          qtd: 13,
          total: 13.4,
          reliable: { value: 50, variant: "warning" },
          order_status: { status: "Available to trade", variant: "primary" },
        },
        {
          seller: { username: "jonatascaires" },
          price: 1.4,
          qtd: 13,
          total: 13.4,
          reliable: { value: 50, variant: "warning" },
          order_status: { status: "In negociation", variant: "warning" },
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>